<template>
    <v-card outlined>
        <v-card-title>
            <v-icon class="mr-3">mdi-school</v-icon>
            Education
        </v-card-title>
        <v-card-text>
            <v-row>

                <v-col cols="12">
                    <v-card tile elevation="0">
                        <v-card-title>Computer Networks & Communication</v-card-title>
                      <v-card-subtitle>Sep 2019 - Sep 2022</v-card-subtitle>
                        <v-card-text>
                            <p>
                                <v-icon class="mr-2">mdi-city</v-icon>
                                <b>Master of Science</b> - <i> Yazd University of Iran </i></p>
                            <p>I proposed an <b>Authentication Scheme For IoT Devices Using Blockchain and
                              Smart Contract</b>. My
                                advisors were
                                <v-btn text small class="blue--text" target="_blank"
                                       href="https://yazd.ac.ir/people/adibnia">Dr.
                                    Fazlollah Adibnia
                                    <v-icon class="ml-2">mdi-web</v-icon>
                                </v-btn> and <v-btn text small class="blue--text" target="_blank"
                                       href="https://yazd.ac.ir/people/mizanian">Dr.
                                    Kiarash Mizanian
                                    <v-icon class="ml-2">mdi-web</v-icon>
                                </v-btn>

                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <v-card tile elevation="0">
                        <v-card-title>Computer Software Engineering</v-card-title>
                        <v-card-subtitle>Sep 2012 - May 2017</v-card-subtitle>
                        <v-card-text>
                            <p>
                                <v-icon class="mr-2">mdi-city</v-icon>
                                <b>Bachelor of Science</b> - <i> Yazd University of Iran </i></p>
                            <p>My project was a Voter Android Application. <b>An Ad-Hoc Network-based Android
                                Application</b>
                                with server & client. The server could enter questions and the clients could vote for
                                questions anonymous or with their name based on their preferred. My advisor for this
                                project was
                                <v-btn text small class="blue--text" target="_blank"
                                       href="https://yazd.ac.ir/people/derhami">Dr. Vali
                                    Derahmi
                                    <v-icon class="ml-2">mdi-web</v-icon>
                                </v-btn>
                                from Yazd University of Iran.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>

    export default {
        name: 'Educations',
        data: () => ({})
    }
</script>
