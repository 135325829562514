<template>
    <v-card tile elevation="0">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="1">
                    <v-img :src="project.logo" :lazy-src="project.logo" :alt="project.name"></v-img>
                </v-col>
                <v-col cols="12" md="9">
                    <v-card-title>{{project.name}}
                        <v-btn class="mx-2" v-if="project.platform.includes('Android')" icon>
                            <v-icon>mdi-android</v-icon>
                        </v-btn>

                    </v-card-title>
                    <v-card-subtitle>{{project.year}}</v-card-subtitle>
                    <v-card-text>
                        {{project.description}}
                    </v-card-text>
                </v-col>
            </v-row>


        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        name: 'Project',
        props: ['project'],

    }
</script>