<template>
    <v-app id="inspire">


        <v-app-bar app color="#17242A" light>
            <v-toolbar-title class="white--text"><b>Falhad.dev</b></v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn class="mx-2" @click="navToBlog">
                <v-icon class="mr-3">mdi-lead-pencil</v-icon>
                Blog
            </v-btn>

            <v-btn>
                <v-icon class="mr-3">mdi-account</v-icon>
                About
            </v-btn>

        </v-app-bar>

        <v-content>
            <router-view/>
        </v-content>
        <v-footer absolute color="white" light app></v-footer>
    </v-app>
</template>

<script>
    export default {
        data: () => ({
            drawer: null
        }), methods: {
            navToBlog() {
                window.location = '/blog';
            },
        }
    }
</script>
