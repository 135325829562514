<template>
  <v-card outlined>
    <v-card-title>
      <v-icon class="mr-3">mdi-briefcase</v-icon>
      Experience
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" v-for="experience in experiences"
               :key="experience.company">
          <Experience :experience="experience"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Experience from "./Experience"

export default {
  name: 'Experiences',
  components: {Experience},
  data: () => ({
    experiences: [
      {
        company: "Biratex Co",
        jobTitle: "Android Developer",
        year: "Jan 2021 - Feb 2022",
        description: "Design & develop cryptocurrency exchange platform.",
      },
      {
        company: "Fardad Co",
        jobTitle: "Android Developer",
        year: "March 2019 - January 2020",
        description: "Working on a lending platform based on blockchain technology and cryptocurrencies.",
      }, {
        company: "Sandbad Accelerator",
        jobTitle: "Consultant & Mentor",
        year: "Dec 2017 - Now",
        description: "Share my knowledge and experience for startup teams.",
      }, {
        company: "Pixit",
        jobTitle: "Co-Founder & Developer",
        year: "Jun 2017 - Dec 2018",
        description: "Online Print Service Using Telegram Bot",
      }, {
        company: "Asha Co (SarvSoft)",
        jobTitle: "Fullstack Developer",
        year: "Dec 2016 - Apr 2018",
        description: "First experience in startup space.",
      }, {
        company: "Pishtazan Setare Isatis",
        jobTitle: "Web Developer",
        year: "Oct 2015 - Nov 2016",
        description: "Developing  PMS & CPMS (software solution that controls parking capacity and car traffic based on RFID & Rasp Pi.) for organizations.",
      },
      {
        company: "Pishgaman IRSA",
        jobTitle: "Web & Android Developer",
        year: "Dec 2013 - Oct 2014",
        description: "Working on an online food delivery system named PitzaKharid.",
      },
    ]
  })
}
</script>