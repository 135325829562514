<template>
    <v-card tile elevation="0" >
        <v-card-title>{{experience.company}}</v-card-title>
        <v-card-subtitle>{{experience.year}}</v-card-subtitle>
        <v-card-text>
            <p><v-icon class="mr-2">mdi-electron-framework</v-icon>
                <b>{{experience.jobTitle}}</b></p>
            <p> <v-icon class="mr-2">mdi-menu-right</v-icon>  {{experience.description}}</p>
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        name: 'Experience',
        props: ['experience'],
    }
</script>