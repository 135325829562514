<template>
    <v-card tile elevation="0">
        <v-card-title>{{skill.title}}</v-card-title>
        <v-card-text>
            <p v-for="meta in skill.metas" :key="meta.key">
                <v-icon class="mr-2">mdi-menu-right</v-icon>
                <b>{{meta.key}}</b> {{meta.value}}
            </p>
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        name: 'Skill',
        props: ['skill'],
    }
</script>