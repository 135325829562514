<template>
    <v-card outlined>
        <v-card-title>
            <v-icon class="mr-3">mdi-medal</v-icon>
            Rewards & Certificates
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" v-for="reward in rewards"
                       :key="reward.title">
                    <Reward :reward="reward"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    import Reward from "./Reward"

    export default {
        name: 'Rewards',
        components: {Reward},
        data: () => ({
            rewards: [
                {
                    title: "ACM Programming Contests Asia/Tehran",
                    metas: [
                        {
                            key: "2013",
                            value: "https://drive.google.com/open?id=1u2O2_o8We-MFUSigvnTMJVG0crXPwNww"
                        },
                        {
                            key: "2014",
                            value: "https://drive.google.com/open?id=1jHEI-N3NxnSqfDq9rvXdFqLeDLcYwdR-"
                        },
                    ]
                },
                {
                    title: "JCal Contests",
                    metas: [
                        {key: "Rank 4 in JCal 5-8", value: "https://javacup.ir/jcal-second-series-ranking/"},
                    ]
                },
                {
                    title: "Sharif University Android Workshops",
                    metas: [
                        {
                            key: "Restaurant Simulation App Workshop",
                            value: "https://drive.google.com/open?id=1Itis-Gw6x9lIPPBjy-EaQ-1bn4VakZPI"
                        },
                        {
                            key: "E-Store Design With Android Programming Workshop",
                            value: "https://drive.google.com/open?id=1uNGf6G7W9EZ__FG4Jko-OGFGZXOfrjDz"
                        },
                        {
                            key: "Laitec Android Shop",
                            value: "https://drive.google.com/open?id=1iKzXfd6aGodeeJynhTl5C5GLY6zTUT_O"
                        },
                        {
                            key: "Laitec Android App",
                            value: "https://drive.google.com/open?id=1eV8734Dy_1ssLVQ6YKW4gBlcf7wycxWN"
                        },
                    ]
                },
                {
                    title: "Java Security Certificate",
                    metas: [
                        {
                            key: "From cert.ir",
                            value: "https://drive.google.com/open?id=1bmeJ-Hubz-ovqnJO4WK2s0GcQCj9-jT6"
                        },
                    ]
                }, {
                    title: "Networking Certificates",
                    metas: [
                        {
                            key: "Network+",
                            value: "https://drive.google.com/file/d/1rufY3no366dEK4Qz5NE6OON3Bcq-mFAo/view?usp=sharing"
                        },
                        {
                            key: "CCNA",
                            value: "https://drive.google.com/file/d/1ZvwALZUGgKvjFyttmnnQ6wNfqns2vWbL/view?usp=sharing"
                        },
                        {
                            key: "CCNP",
                            value: "https://drive.google.com/file/d/1qBbgoK3mLUIJaAtlgyDnUewcWfdKFDuN/view?usp=sharing"
                        },
                        {
                            key: "CCNP Route/Switch",
                            value: "https://drive.google.com/file/d/1jSktgIiziWVGC61ASTeCXP8LZSv1hXsq/view?usp=sharing"
                        },
                        {
                            key: "MCITP",
                            value: "https://drive.google.com/file/d/1bSQ8I86w1pzpaKg4VNvFagzKGQKC8dbX/view?usp=sharing"
                        },
                    ]
                },

            ]
        })
    }
</script>