<template>
    <v-container fluid>

        <v-btn fab dark color="amber darken-1" fixed right bottom @click="$vuetify.goTo('.quick-access', options)">
            <v-icon dark>mdi-flash</v-icon>
        </v-btn>

        <v-card tile elevation="0">
            <v-card-text>

                <v-row>
                    <v-col cols="12" md="3">
                        <v-row>

                            <v-col cols="12" align="center">
                                <v-avatar size="250">
                                    <img :src="avatar" alt="Farhad">
                                </v-avatar>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" md="9" align="left">
                        <v-row>
                            <v-col cols="12"><h1 class="pt-10">Hi 👋, I'm Farhad </h1></v-col>
                            <v-col cols="12"><h3>Software Engineer 🧑‍💻</h3>
                            </v-col>
                            <v-col cols="12" class="grey--text darken-4"><h4>I am Farhad Navayazdan. I am an Android
                                Developer, Linux ❤️
                              lover and interested in Startups. I enjoy each aspect
                                of
                                building apps from start to end. My main focus is on building Android Apps and Web APIs
                                using
                                Kotlin. </h4></v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="3">
                        <v-list two-line>

                            <v-list-item @click="dialPhone()">
                                <v-list-item-icon>
                                    <v-icon color="grey">mdi-phone</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>(98) 930-754-0810</v-list-item-title>
                                    <v-list-item-subtitle>Mobile</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="sendEmail()">
                                <v-list-item-icon>
                                    <v-icon color="grey">mdi-inbox</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{email}}</v-list-item-title>
                                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>


                            <v-list-item @click="openTelegram">
                                <v-list-item-icon>
                                    <v-icon color="grey">mdi-send</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>@{{telegramId}}</v-list-item-title>
                                    <v-list-item-subtitle>Telegram</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="openInstagram">
                                <v-list-item-icon>
                                    <v-icon color="grey">mdi-instagram</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>@{{instagramId}}</v-list-item-title>
                                    <v-list-item-subtitle>Instagram</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="openLinkedin">
                                <v-list-item-icon>
                                    <v-icon color="grey">mdi-linkedin</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{linkedInId}}</v-list-item-title>
                                    <v-list-item-subtitle>Linkedin</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="openGitlab">
                                <v-list-item-icon>
                                    <v-icon color="grey">mdi-gitlab</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{gitlabId}}</v-list-item-title>
                                    <v-list-item-subtitle>Gitlab</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="openGithub">
                                <v-list-item-icon>
                                    <v-icon color="grey">mdi-github</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{githubId}}</v-list-item-title>
                                    <v-list-item-subtitle>Github</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>


                            <v-list-item @click="openStackOverFlow">
                                <v-list-item-icon>
                                    <v-icon color="grey">mdi-stack-overflow</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-img src="https://stackoverflow.com/users/flair/3917083.png?theme=clean"
                                               :aspect-ratio="3.58" max-width="208" max-height="58"/>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>StackOverFlow</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>


                        </v-list>
                    </v-col>


                    <v-col cols="12" md="9" align="left">
                        <v-row>

                            <v-col cols="12" class="quick-access">
                                <v-card outlined>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <h4>
                                                    <v-icon class="mr-2 grey--text">mdi-flash</v-icon>
                                                    You can Quick Access To Desire Sections Via this Buttons
                                                </h4>
                                            </v-col>

                                            <v-col cols="12" md="3">
                                                <v-btn block outlined @click="$vuetify.goTo('.skills', options)">
                                                    <v-icon class="mr-2 grey--text">mdi-star-face</v-icon>
                                                    Skills
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <v-btn block outlined @click="$vuetify.goTo('.rewards', options)">
                                                    <v-icon class="mr-2 grey--text">mdi-medal</v-icon>
                                                    Rewards
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <v-btn block outlined @click="$vuetify.goTo('.experiences', options)">
                                                    <v-icon class="mr-2 grey--text">mdi-briefcase</v-icon>
                                                    Experiences
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <v-btn block outlined @click="$vuetify.goTo('.educations', options)">
                                                    <v-icon class="mr-2 grey--text">mdi-school</v-icon>
                                                    Educations
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <v-btn block outlined @click="$vuetify.goTo('.projects', options)">
                                                    <v-icon class="mr-2 grey--text">mdi-folder-star</v-icon>
                                                    Projects
                                                </v-btn>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-card>

                            </v-col>

                            <v-col cols="12" class="skills">
                                <Skills/>
                            </v-col>
                            <v-col cols="12" class="rewards">
                                <Rewards/>
                            </v-col>

                            <v-col cols="12" class="experiences">
                                <Experiences/>
                            </v-col>

                            <v-col cols="12" class="educations">
                                <Educations/>
                            </v-col>

                            <v-col cols="12" class="projects">
                                <Projects/>
                            </v-col>

                        </v-row>

                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </v-container>

</template>

<script>

    import Educations from "./Educations";
    import Projects from "./Projects";
    import Rewards from "./Rewards";
    import Skills from "./Skills";
    import Experiences from "./Experiences";

    export default {
        name: 'home',
        components: {Skills, Rewards, Experiences, Projects, Educations},
        methods: {
            sendEmail() {
                window.location = 'mailto:' + this.email;
            }, dialPhone() {
                window.location = 'tel:' + this.mobile;
            }, openTelegram() {
                window.location = 'tg://resolve?domain=' + this.telegramId
            }, openInstagram() {
                window.open('https://www.instagram.com/' + this.instagramId, '_blank');
            }, openLinkedin() {
                window.open('https://www.linkedin.com/in/' + this.linkedInId, '_blank');
            }, openStackOverFlow() {
                window.open('https://stackoverflow.com/users/3917083/farhad-arcxx', '_blank');
            }, openGitlab() {
                window.open('https://gitlab.com/' + this.gitlabId, '_blank');
            }, openGithub() {
                window.open('https://github.com/' + this.githubId, '_blank');
            },
        },
        data: () => ({
            options: {
                duration: 300,
                offset: 0,
                easing: 'easeInOutCubic',
            },
            email: 'cs.arcxx@gmail.com',
            mobile: '989307540810',
            telegramId: 'falhawd',
            gitlabId: 'falhad',
            githubId: 'falhad',
            instagramId: 'farhad.arcxx',
            linkedInId: 'farhadarcxx',
            toggle_exclusive: undefined,
            avatar: require('../assets/falhad.jpg'),
        }),
    }
</script>
