<template>
  <v-card outlined>
    <v-card-title>
      <v-icon class="mr-3">mdi-folder-star</v-icon>
      Projects
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12"
               v-for="project in projects"
               :key="project.name">
          <Project :project="project"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Project from "./Project"

export default {
  name: 'Projects',
  components: {Project},
  data: () => ({
    projects: [
      {
        name: "Sipan",
        logo: require('../assets/projects/sipan.png'),
        year: "2021", platform: "Android",
        description: "Industry inspection app for using in power plants and etc",
      },
      {
        name: "Biratex",
        logo: require('../assets/projects/biratex.png'),
        year: "2021", platform: "Android",
        description: "Cryptocurrency Exchange App.",
      },
      {
        name: "TileMarket",
        logo: require('../assets/projects/tilemarket.png'),
        year: "2019", platform: "Android",
        description: "Online tile sale solution.",
      },
      {
        name: "Medaad",
        logo: require('../assets/projects/medad.png'),
        year: "2019", platform: "Android",
        description: "Quiz App for Students.",
      }, {
        name: "Wallpepper",
        logo: require('../assets/projects/wallpepper.jpg'),
        year: "2019", platform: "Android",

        description: "Wallpepper is an Android Wallpaper App with Auto changing wallpaper ability. In this app the admin of the system has an online panel that can upload wallpaper in some categories like random, popular, featured, editor’s choice, recent, special events, etc. and the user can select wallpapers from these categories. This app also has the lock screen wallpaper change feature and can suggest wallpapers based on user device screen size.\n",
      }, {
        name: "Carwasheto",
        logo: require('../assets/projects/carwasheto.png'),
        year: "2019", platform: "Android",

        description: "Online car wash request service startup.",
      },
      {
        name: "Parastoo",
        logo: require('../assets/projects/parastoo.png'),
        year: "2019", platform: "Android",

        description: "Parastoo is and android application app for finding friends. In this app, users can search each other based on some filters like city, age, etc. people also can specify their interests for better search results. Users can message each other in this app and also can share their photos on this platform.\n",
      }, {
        name: "Lopak",
        logo: require('../assets/projects/lopak.png'),
        year: "2019",
        platform: "Android",
        description: " An app that helps people make money from their garbage.",
      }, {
        name: "Pixit",
        logo: require('../assets/projects/pixit.jpg'),
        year: "2018",
        platform: "Android",
        description: "Pixit is a photo printing platform based on the telegram messenger platform that excuses telegram security and the ease of sharing images across the telegram to provide a simple, user-friendly environment for register orders. client’s photos are then processed by the operator for printing and sent to the relevant units and the user delivers the desired images to the door as quickly as possible without having to wait for the photo to be printed.\n",
      }, {
        name: "NazarPlus",
        logo: require('../assets/projects/nazarpluslogo.jpg'),
        year: "2018",
        platform: "Android",
        description: "With NazarPlus, consumers and buyers of online services can submit their reviews and rate for the seller product or service. This will helps people around the country to find out if they can trust a product or service or not, and can help service providers to promote their services if they are good enough.\n",
      }, {
        name: "Kish Drivers",
        logo: require('../assets/projects/kish.png'),
        year: "2018",
        platform: "Android",
        description: "A simple app that uses GSET company API to show the location of taxies in Kish Island that use the GSET Tracker. (this app isn’t for public use)",
      }, {
        name: "Noisly",
        logo: require('../assets/projects/noisly.jpg'),
        year: "2018",
        platform: "Android",
        description: "Noisly is an android music player with predefined music for sleep or a better mood.\n",
      }, {
        name: "ToneTube ",
        logo: require('../assets/projects/tonetube.jpg'),
        year: "2017",
        platform: "Android",
        description: "With ToneTube social media people can share their favorite songs & music with each other. Another section of this app is karaoke that lets users sing a song with a melody peace rhythm.\n",
      }, {
        name: "Xfit  ",
        logo: require('../assets/projects/xfit.jpg'),
        year: "2017",
        platform: "Android",
        description: "Xfit is an online gym panel for a coach for planning and tracks athletics with features like gym workout and food plan, coach consulting, movements tutorials, track user progress, submit daily journals, daily motivational contents, articles about exercises, water reminder, etc.\n",
      }, {
        name: "Fitpaz",
        logo: require('../assets/projects/fitpaz.png'),
        year: "2017",
        platform: "Android",
        description: "Nutrition and food prepare guide for peoples that go to the gym or workout.",
      }, {
        name: "Voter",
        logo: require('../assets/projects/voter.png'),
        year: "2016",
        platform: "Android",
        description: "This app was developed as my BS project at Yazd University of Iran.",
      }, {
        name: "JahanSales",
        logo: require('../assets/projects/jes.png'),
        year: "2015",
        platform: "Android",
        description: "A Sales solution for Jahan Electronic Company. This app is just for some VIP clients and its not available for public use.",
      }, {
        name: "Moshtary",
        logo: require('../assets/projects/moshtary.png'),
        year: "2015",
        platform: "Android",
        description: "My first android app that was released in the market. This app was like Divar.ir app with some differences in the business model.",
      },

    ]

  })
}
</script>