<template>
    <v-card tile elevation="0">
        <v-card-title>{{reward.title}}</v-card-title>
        <v-card-text>
            <p v-for="meta in reward.metas" :key="meta.key">
                <v-icon class="mr-2">mdi-menu-right</v-icon>
                <b>{{meta.key}}</b>
                <v-btn text small class="blue--text" target="_blank" :href="meta.value">(more..)</v-btn>
            </p>
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        name: 'Reward',
        props: ['reward'],

    }
</script>