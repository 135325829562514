<template>
    <v-card outlined>
        <v-card-title>
            <v-icon class="mr-3">mdi-star-face</v-icon>
            Skills
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" v-for="skill in skills"
                       :key="skill.title">
                    <Skill :skill="skill"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    import Skill from "./Skill"

    export default {
        name: 'Skills',
        components: {Skill},
        data: () => ({
            skills: [
                {
                    title: "Mobile Programming",
                    metas: [
                        {
                            key: "Android (Native)",
                            value: "Working with Java / Kotlin with +5 years of experience. Familiar with MVP, MVVM, Jetpack Compose, SOLID, Kotlin Coroutines etc."
                        },
                        {key: "Flutter", value: "Newbie"},
                    ],
                },
                {
                    title: "Backend & Server",
                    metas: [
                        {key: "Spring Boot", value: "Java / Kotlin"},
                        {key: "Mongodb", value: "Basic Knowledge"},
                        {key: "Netty / Socket.IO", value: ""},
                    ],
                }, {
                    title: "Frontend & Website",
                    metas: [
                        {key: "Vuejs / Nuxt", value: ""},
                        {key: "Flutter", value: "Newbie"},
                        {key: "Wordpress", value: ""},
                    ],
                },
                {
                    title: "Design Skills",
                    metas: [
                        {key: "Adobe Photoshop", value: ""},
                        {key: "Adobe Illustrator", value: ""},
                        {key: "Figma", value: ""},
                        {key: "Krita", value: ""}
                    ],
                }, {
                    title: "Linux Distros",
                    metas: [
                        {key: "Debian", value: "Mint / Ubuntu"},
                        {key: "Arch", value: "Manjaro"},
                    ],
                }, {
                    title: "Networking",
                    metas: [
                      {key: "Rest APIs", value: ""},
                      {key: "Socket Programming", value: ""},
                      {key: "Cisco", value: ""},
                    ],
                }
            ]
        })
    }
</script>